<template>
  <div class="main">
    <div class="banner"></div>
    <div class="cha">
      <div class="title_b">
        <b>产品概述</b>
        <div class="s">
          基于微信小程序和AI智能技术，赋能一线销售，帮助企业获客，<br />
          使管理者、员工、客户均受益的高科技智能名片。
        </div>
        <div class="icon"></div>
      </div>
      <div class="content df">
        <div class="card" v-for="(i, index) in cl" :key="index">
          <img :src="i.img" alt="" />
          <div class="title">
            {{ i.t1 }} · <span :style="{ color: i.color }">{{ i.t2 }}</span>
          </div>
          <div>{{ i.text }}</div>
        </div>
      </div>
    </div>
    <div class="title_b">
      <b>建立智能管理体系 打造全员营销模式</b>
      <div class="s">
        借助行业最佳实践的营销管理经验，助力企业进入全员营销时代
      </div>
      <div class="icon"></div>
    </div>
    <div v-for="(i, index) in fl" :key="index">
      <div class="f_card" v-if="i.type == 1">
        <img :src="i.img" alt="" />
        <div class="info">
          <div
            class="title"
            :style="{ backgroundImage: 'url(' + i.title_img + ')' }"
          >
            {{ i.title }}
          </div>
          <div class="item" v-for="(e, ei) in i.list" :key="ei">
            <div class="t">{{ e.t }}</div>
            <div class="s">{{ e.s }}</div>
          </div>
        </div>
      </div>
      <div class="f_card" v-if="i.type == 0">
        <div class="info">
          <div
            class="title"
            :style="{ backgroundImage: 'url(' + i.title_img + ')' }"
          >
            {{ i.title }}
          </div>
          <div class="item" v-for="(e, ei) in i.list" :key="ei">
            <div class="t">{{ e.t }}</div>
            <div class="s">{{ e.s }}</div>
          </div>
        </div>
        <img :src="i.img" alt="" />
      </div>
      <div class="bg" v-if="i.type == 2">
        <div class="f_card">
          <img :src="i.list[0].img" alt="" />
          <div class="info">
            <div
              class="title"
              :style="{ backgroundImage: 'url(' + i.list[0].title_img + ')' }"
            >
              {{ i.list[0].title }}
            </div>
            <div class="item" v-for="(e, ei) in i.list[0].list" :key="ei">
              <div class="t">{{ e.t }}</div>
              <div class="s">{{ e.s }}</div>
            </div>
          </div>
        </div>
        <div class="f_card">
          <div class="info">
            <div
              class="title"
              :style="{ backgroundImage: 'url(' + i.list[1].title_img + ')' }"
            >
              {{ i.list[1].title }}
            </div>
            <div class="item" v-for="(e, ei) in i.list[1].list" :key="ei">
              <div class="t">{{ e.t }}</div>
              <div class="s">{{ e.s }}</div>
            </div>
          </div>
          <img :src="i.list[1].img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
    
      cl: [
        {
          img: require("../../assets/img/productList/sc/card_img_1.jpg"),
          t1: "一张",
          t2: "智能名片",
          color: "#2788f9",
          text: "个人信息|企业官网|产品商城|新闻动态模板化的个性名片，风格多样化适用于不同行业和用途",
        },
        {
          img: require("../../assets/img/productList/sc/card_img_2.jpg"),
          t1: "一套",
          t2: "营销工具",
          color: "#3b43b1",
          text: "访客咨询、浏览信息会及时通知销售，销售第一时间了解相关提醒 | AI精准数据分析，打通销售各个环节 | IM聊天系统，不加微信随时沟通",
        },
        {
          img: require("../../assets/img/productList/sc/card_img_3.jpg"),
          t1: "一个",
          t2: "管理系统",
          color: "#611fd0",
          text: "对企业的基本资料，产品、员工、财务、客户等管理，对客户的分配，随时随地管理企业的智享AI互推名片系统",
        },
      ],
      fl: [
        {
          type: "1",
          img: require("../../assets/img/productList/sc/card_function_1.png"),
          title_img: require("../../assets/img/productList/sc/c_t_1.png"),
          title: "一张“会说话”的名片：个人信息一目了然",
          list: [
            {
              t: "个人中心",
              s: "展示收藏的名片信息，可以置顶、删除",
            },
            {
              t: "名片详情",
              s: "个人联系方式、形象照片、推广商品一目了然",
            },
            {
              t: "个人简介",
              s: "个人简介、印象标签、照片帮助客户进一步加深了解",
            },
          ],
        },
        {
          type: "0",
          img: require("../../assets/img/productList/sc/card_function_2.png"),
          title_img: require("../../assets/img/productList/sc/c_t_2.png"),
          title: "一张“会赚钱”的名片：自带电商能力",
          list: [
            {
              t: "商品便捷发布",
              s: "商品一键上传、快速发布；订单、营收、客户等数据，后台全掌握，轻松管理",
            },
            {
              t: "全品类支持",
              s: "支持实物商品交易、虚拟商品交易、业务的办理、商品预约销售等",
            },
            {
              t: "信息实时提醒",
              s: "客户浏览、下单信息实时提醒，实时掌握行为数据",
            },
          ],
        },
        {
          type: "2",
          list: [
            {
              type: "1",
              img: require("../../assets/img/productList/sc/card_function_3.png"),
              title_img: require("../../assets/img/productList/sc/c_t_3.png"),
              title: "一张“会宣传”的名片：公司动态直观查看",
              list: [
                {
                  t: "公司资讯",
                  s: "通过公司资讯，实时查看公司新推出的政策、商品、动态，方便用户及时了解公司情况。",
                },
                {
                  t: "名片详情",
                  s: "通过公司官网，让用户对公司概况、产品信息、联系方式等信息，全部获悉",
                },
              ],
            },
            {
              type: "0",
              img: require("../../assets/img/productList/sc/card_function_4.png"),
              title_img: require("../../assets/img/productList/sc/c_t_4.png"),
              title: "快速连接客户，通过标签智能管理用户",
              list: [
                {
                  t: "与客户快速链接",
                  s: "小程序名片快速连接客户，无需添加好友实现即时沟通",
                },
                {
                  t: "数据化管理客户",
                  s: "获客数据精准标签化维护促成简单，高效智能化的客户管理",
                },
              ],
            },
          ],
        },
        {
          type: "1",
          img: require("../../assets/img/productList/sc/card_function_5.png"),
          title_img: require("../../assets/img/productList/sc/c_t_5.png"),
          title: "AI雷达实时掌握客户信息 ",
          list: [
            {
              t: "用户雷达",
              s: "用户雷达实时掌握客户信息",
            },
            {
              t: "数据采集",
              s: "自动采集访客互动，全面展现互动数据",
            },
            {
              t: "数据分析",
              s: "智能分析用户行为、用户关注和兴趣点",
            },
          ],
        },
        {
          type: "0",
          img: require("../../assets/img/productList/sc/card_function_6.png"),
          title_img: require("../../assets/img/productList/sc/c_t_6.png"),
          title: "持续跟进客户，提升客户意向 ",
          list: [
            {
              t: "建立持续互动",
              s: "以客户交互为中心，与客户持续连接",
            },
            {
              t: "沟通社群",
              s: "通过社群运营和互动，让员工、企业与客户持续互动，实现与客户的持续连接",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("../../assets/img/productList/sc/banner.jpg");
}
.cha {
  background: url("../../assets/img/productList/sc/card_bg_1.jpg") center 0 /
    2560px auto;
  padding-bottom: 90px;
  .df {
    display: flex;
    justify-content: space-between;
    .card {
      width: 340px;
      padding: 25px;
      background-color: #ffffff;
      box-shadow: 0px 5px 20px 0px rgba(53, 53, 53, 0.06);
      border-radius: 20px;
      border: solid 1px #f3f3f3;
      line-height: 34px;
      position: relative;
      top: 0;
      transition: 0.3s;
      &:hover {
        top: -10px;
        box-shadow: 0px 5px 20px 0px rgba(35, 147, 242, 0.08);
      }
      img {
        width: 340px;
      }
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        margin: 43px 0;
      }
    }
  }
}
.f_card {
  display: flex;
  width: 1260px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 70px;
  justify-content: space-between;
  img {
    width: 600px;
    height: 450px;
  }
  .info {
    width: 580px;
    .title {
      height: 85px;
      background-size: auto 75px;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-size: 26px;
      font-weight: bold;
      line-height: 120px;
      color: #000000;
    }
    .item {
      font-size: 16px;
      color: #717171;
      position: relative;
      left: 26px;
      margin-bottom: 40px;
      .t {
        font-size: 18px;
        font-weight: bold;
        color: #2d4877;
        margin-bottom: 13px;
      }
      &::before {
        position: absolute;
        left: -26px;
        top: 10px;
        width: 13px;
        height: 13px;
        content: "";
        display: block;
        background: url("../../assets/img/productList/sc/icon.png") 0 0 / 100%;
      }
    }
  }
}
.bg {
  background: url("../../assets/img/productList/sc/card_bg2.jpg") center 0 /
    2560px auto;
}
</style>